// @ts-check

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalAuthLoader } from 'components/global-loader';
import {
  SIGNOUT_EMAIL_SEARCH_PARAM,
  SIGNOUT_IS_EMAIL_VERIFY_SEARCH_PARAM,
  SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM,
  SIGNOUT_IS_PASSWORDLESS_SEARCH_PARAM,
  SIGNOUT_IS_SSO_ERROR,
  SIGNOUT_IS_STEPUP_REAUTHENTICATE_SEARCH_PARAM,
  SIGNOUT_KEEP_REDIRECT_COOKIE,
} from 'constants/constant';
import { NEW_AUTH_SIGN_IN_ROUTE } from 'constants/routes';
import { userSignOut } from 'redux/actions';
import { getIsPasswordlessFlow } from 'util/auth/auth0.utils';
import { getToken } from 'util/auth/get-token';
import { jwtDecode } from 'jwt-decode';

/**
 * Generates the search parameters for the Universal Login template URL.
 *
 * @param {Object} options - The options for generating the search parameters.
 * @param {boolean} options.isInactivityReauthenticate - Indicates if the user is reauthenticating due to inactivity.
 * @param {boolean} options.isStepUpReauthenticate - Indicates if the user is reauthenticating for a step-up authentication.
 * @param {boolean} options.isEmailVerify - Indicates if the user is reauthenticating for email verification.
 * @param {boolean} options.isPasswordless - Indicates if the user has passwordless flow.
 * @param {string} options.email - The email associated with the user.
 * @returns {string} The generated search parameters as a string.
 */
const getUniversalLoginTemplateSearchParams = ({
  isInactivityReauthenticate,
  isStepUpReauthenticate,
  isEmailVerify,
  isPasswordless,
  email,
}) => {
  const searchParams = new URLSearchParams();

  if (isInactivityReauthenticate) {
    searchParams.set(SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM, 'true');
  }
  if (isStepUpReauthenticate) {
    searchParams.set(SIGNOUT_IS_STEPUP_REAUTHENTICATE_SEARCH_PARAM, 'true');
  }
  if (isEmailVerify) {
    searchParams.set(SIGNOUT_IS_EMAIL_VERIFY_SEARCH_PARAM, 'true');
  }
  if (isPasswordless) {
    searchParams.set(SIGNOUT_IS_PASSWORDLESS_SEARCH_PARAM, 'true');
  }
  if (email) {
    searchParams.set(SIGNOUT_EMAIL_SEARCH_PARAM, email);
  }

  return searchParams.toString();
};

// Route responsible for initiating user sign out.
// Required by dual app setup.
export default function SignOutPage() {
  const dispatch = useDispatch();

  const authState = useSelector((state) => state.auth);
  const producerEmail = authState?.producerEmail;

  async function getIsUserAwaitingEmailVerification() {
    const token = await getToken();

    // Token should always be there, but theoretically, there's a chance it's not
    if (!token) {
      return false;
    }

    const decodedToken = jwtDecode(token);
    // @ts-expect-error
    return decodedToken.verified === false;
  }

  useEffect(function handleSignout() {
    async function handleSignoutAsync() {
      const isUserAwaitingEmailVerification =
        await getIsUserAwaitingEmailVerification();

      localStorage.removeItem('NEW_SIGN_IN_AUTHENTICATED');

      const params = new URLSearchParams(window.location.search);
      const isInactivityReauthenticate =
        params.get(SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM) ===
        'true';
      const isStepUpReauthenticate =
        params.get(SIGNOUT_IS_STEPUP_REAUTHENTICATE_SEARCH_PARAM) === 'true';
      const isEmailVerify =
        params.get(SIGNOUT_IS_EMAIL_VERIFY_SEARCH_PARAM) === 'true' ||
        isUserAwaitingEmailVerification;
      const isSSOError = params.get(SIGNOUT_IS_SSO_ERROR) === 'true';
      const emailFromParams =
        params.get(SIGNOUT_EMAIL_SEARCH_PARAM) || undefined;

      if (isSSOError) {
        dispatch(
          userSignOut({ returnTo: `/signin?${SIGNOUT_IS_SSO_ERROR}=true` })
        );
        return;
      }

      if (
        isInactivityReauthenticate ||
        isStepUpReauthenticate ||
        isEmailVerify ||
        isSSOError
      ) {
        const searchParams = getUniversalLoginTemplateSearchParams({
          isInactivityReauthenticate,
          isStepUpReauthenticate,
          isEmailVerify,
          isPasswordless: getIsPasswordlessFlow(),
          email: producerEmail || emailFromParams || '',
        });
        const returnTo = `${NEW_AUTH_SIGN_IN_ROUTE}?${searchParams}`;
        dispatch(
          userSignOut({
            returnTo,
            keepRedirectCookie:
              params.get(SIGNOUT_KEEP_REDIRECT_COOKIE) === 'true',
          })
        );
        return;
      }

      dispatch(
        userSignOut({
          returnTo: NEW_AUTH_SIGN_IN_ROUTE,
          keepRedirectCookie:
            params.get(SIGNOUT_KEEP_REDIRECT_COOKIE) === 'true',
        })
      );
    }
    handleSignoutAsync();
  }, []);

  return <GlobalAuthLoader />;
}
