import * as type from '../../constants/action-types';

export const fetchProducerFlags = () => ({
  type: type.FETCH_PRODUCER_FLAGS,
});

/**
 * @param {boolean} isEnabled
 * @returns {{type: string, payload: boolean}}
 */
export const setIsReferralProgramEnabled = (isEnabled) => ({
  type: type.SET_IS_REFERRAL_PROGRAM_ENABLED,
  payload: isEnabled,
});

/**
 * @param {'legacy' | 'new'} isEnabled
 * @returns {{type: string, payload: boolean}}
 */
export const setSignUpFlowType = (signUpFlowType) => ({
  type: type.SET_SIGN_UP_FLOW_TYPE,
  payload: signUpFlowType,
});
