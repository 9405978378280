// @ts-check

import React, { Fragment, useEffect, useState } from 'react';
import {
  basicLogger,
  LDProvider,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';

import config from 'config';
import { GlobalAuthLoader } from 'components/global-loader';
import {
  useLaunchDarklyInitContext,
  useUserContext,
} from './launch-darkly-provider.hooks';

/**
 * @param {object} props
 * @param {string | null} props.producerRole
 * @param {string | null} props.producerCode
 * @param {'legacy' | 'new' | null} props.signUpFlowType
 * @param {React.ReactNode} props.children
 */
function LaunchDarklyProvider({
  children,
  producerCode,
  producerRole,
  signUpFlowType,
}) {
  const initContext = useLaunchDarklyInitContext();

  // @ts-expect-error config can't resolve envs coming from appConfig.js
  if (!config.LAUNCH_DARKLY_CLIENT_ID) {
    console.warn(
      'LaunchDarkly not initialized, missing LAUNCH_DARKLY_CLIENT_ID'
    );

    return <Fragment>{children}</Fragment>;
  }

  return (
    <LDProvider
      // @ts-expect-error config can't resolve envs coming from appConfig.js
      clientSideID={config.LAUNCH_DARKLY_CLIENT_ID}
      timeout={2}
      context={initContext}
      options={{ logger: basicLogger({ level: 'error' }) }}
    >
      <LaunchDarklyLoader
        producerCode={producerCode}
        producerRole={producerRole}
        signUpFlowType={signUpFlowType}
      >
        {children}
      </LaunchDarklyLoader>
    </LDProvider>
  );
}

/**
 * @param {object} props
 * @param {string | null} props.producerRole
 * @param {string | null} props.producerCode
 * @param {'legacy' | 'new' | null} props.signUpFlowType
 * @param {React.ReactNode} props.children
 */
function LaunchDarklyLoader({
  children,
  producerCode,
  producerRole,
  signUpFlowType,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const ldClient = useLDClient();
  const userContext = useUserContext({
    role: producerRole,
    code: producerCode,
    signUpFlowType,
  });

  // Wait until LD is done pulling flags before rendering the children
  useEffect(() => {
    if (!ldClient) {
      return;
    }

    const initializeLaunchDarkly = async () => {
      try {
        await ldClient.waitUntilReady();
        if (!userContext) {
          return;
        }
        await ldClient.identify(userContext);
      } finally {
        setIsLoading(false);
      }
    };

    void initializeLaunchDarkly();
  }, [ldClient, userContext]);

  if (isLoading) {
    return <GlobalAuthLoader />;
  }

  return <Fragment>{children}</Fragment>;
}

export default connect(mapStateToProps)(LaunchDarklyProvider);

function mapStateToProps({ impersonate, auth, producerFlags }) {
  const { producer: impersonateProducer, isImpersonate } = impersonate;
  const { producer_code: producerCode, role: producerRole } = auth;

  return {
    producerRole: isImpersonate ? impersonateProducer.role : producerRole,
    producerCode: isImpersonate
      ? impersonateProducer.producer_code
      : producerCode,
    signUpFlowType: producerFlags.signUpFlowType,
  };
}
