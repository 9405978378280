import * as types from '../../constants/action-types';

const initState = () => ({
  isReferralProgramEnabled: null,
  signUpFlowType: null,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_IS_REFERRAL_PROGRAM_ENABLED: {
      return {
        ...state,
        isReferralProgramEnabled: action.payload,
      };
    }
    case types.SET_SIGN_UP_FLOW_TYPE: {
      return {
        ...state,
        signUpFlowType: action.payload,
      };
    }
    default:
      return state;
  }
};
