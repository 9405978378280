// @ts-check

/** @type {import("./launch-darkly-types").TLaunchDarklyFlag<boolean>} */
const IS_NEW_SIGN_UP_ENABLED = {
  key: 'isNewSignUpEnabled',
  defaultValue: false,
};

export const LAUNCHDARKLY_FLAGS = {
  IS_NEW_SIGN_UP_ENABLED,
};
