// @ts-check

import { NEW_AUTH_SIGN_UP_ROUTE } from 'constants/routes';
import { LAUNCHDARKLY_FLAGS } from 'containers/launch-darkly/launch-darkly-flags';
import { useLaunchDarklyFlag } from 'containers/launch-darkly/launch-darkly-provider.hooks';
import { useEffect } from 'react';
import { navigateToNewAppRoute } from 'util/navigate-to-new-app-route';

function useIsNewSignUpEnabled() {
  const { IS_NEW_SIGN_UP_ENABLED } = LAUNCHDARKLY_FLAGS;

  return useLaunchDarklyFlag(IS_NEW_SIGN_UP_ENABLED);
}

export function NewSignupRedirectWrapper({ children }) {
  const isNewSignUpEnabled = useIsNewSignUpEnabled();

  useEffect(() => {
    if (isNewSignUpEnabled) {
      // Get query params from the URL and remove the initial '?' character
      const queryParams = window.location.search.slice(1);
      navigateToNewAppRoute(NEW_AUTH_SIGN_UP_ROUTE, queryParams);
    }
  }, [isNewSignUpEnabled]);

  return children;
}
