// @ts-check

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { getIsAuthenticatedViaNewApp } from 'util/auth/get-is-authenticated-via-new-app';
import { isAuthenticated as getIsAuthenticatedViaLS } from 'util/auth/is-authenticated';

const LAUNCH_DARKLY_TESTER_CONTEXT_ENABLED_KEY = 'ld:isTesterContextEnabled';
const LAUNCH_DARKLY_TESTER_ID_KEY = 'ld:testerId';

/**
 * @template T
 * @param {import("./launch-darkly-types").TLaunchDarklyFlag<T>} flag
 * @returns {T} flag value
 */
export function useLaunchDarklyFlag(flag) {
  const ldClient = useLDClient();
  const context = ldClient?.getContext();
  const isAuthenticated =
    getIsAuthenticatedViaNewApp() || getIsAuthenticatedViaLS();
  let flagValue = flag.defaultValue;

  const isTesterContextEnabled = getIsLaunchDarklyTesterContextEnabled();
  const isAnonymousUser =
    context && isTesterContextEnabled && 'anonymousUser' in context
      ? context?.anonymousUser.anonymous
      : context?.anonymous;
  // This condition makes sure that we wait until user data is loaded after sign-in
  // before returning value from LaunchDarkly
  if (!isAnonymousUser === isAuthenticated) {
    flagValue =
      ldClient?.variation(flag.key, flag.defaultValue) ?? flag.defaultValue;
  }

  useEffect(() => {
    window.heap.track('LaunchDarkly Experiment Seen', {
      FlagId: flag.key,
      Value: flagValue,
    });
  }, [flagValue, flag.key]);

  return flagValue;
}

/**
 * @param {object} producer
 * @param {string | null} producer.role
 * @param {string | null} producer.code
 * @param {'legacy' | 'new' | null} producer.signUpFlowType
 * @returns {import('launchdarkly-react-client-sdk').LDContext | undefined}
 */
export function useUserContext(producer) {
  const ldClient = useLDClient();

  if (
    !ldClient ||
    !producer.code ||
    !producer.role ||
    !producer.signUpFlowType
  ) {
    return undefined;
  }

  const previousContext = ldClient.getContext();

  if (
    getIsLaunchDarklyTesterContextEnabled() &&
    'anonymousUser' in previousContext &&
    'testerUser' in previousContext
  ) {
    return {
      kind: 'multi',
      anonymousUser: {
        key: previousContext.anonymousUser.key,
      },
      testerUser: {
        key: previousContext.testerUser.key,
      },
      user: {
        key: producer.code,
        userRole: producer.role,
        signUpFlowType: producer.signUpFlowType,
      },
    };
  }

  return {
    kind: 'multi',
    anonymousUser: {
      key: previousContext.key,
    },
    user: {
      key: producer.code,
      userRole: producer.role,
      signUpFlowType: producer.signUpFlowType,
    },
  };
}

export function useLaunchDarklyInitContext() {
  if (getIsLaunchDarklyTesterContextEnabled()) {
    return {
      kind: 'multi',
      anonymousUser: {
        anonymous: true,
      },
      testerUser: {
        anonymous: true,
        key: localStorage.getItem(LAUNCH_DARKLY_TESTER_ID_KEY) ?? undefined,
      },
    };
  }

  return {
    kind: 'anonymousUser',
    anonymous: true,
  };
}

function getIsLaunchDarklyTesterContextEnabled() {
  return (
    localStorage.getItem(LAUNCH_DARKLY_TESTER_CONTEXT_ENABLED_KEY) === 'true'
  );
}
